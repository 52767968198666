import anime from 'animejs'
import isArray from 'lodash/isArray'

type AnimationConfig = {
  [key: keyof anime.AnimeParams]: unknown
}

export type AnimationOptions = {
  animationOptions: AnimationConfig | AnimationConfig[]
  loop?: boolean
}

type AnimationProps = AnimationOptions & {
  // Corresponds to type `AnimeTarget` of `animejs`
  element: string | object | HTMLElement | SVGElement | NodeList | null
  loop?: boolean
}

/**
 *
 * @param animationProps - a configuration of animation properties
 * @param element - the target element to apply the animation to
 * @returns void
 */
export default function applyAnimation(animationProps: AnimationProps) {
  const { animationOptions, element, loop } = animationProps

  if (isArray(animationOptions)) {
    const timeline = anime.timeline({
      ...animationOptions[0],
      loop,
    })

    for (let i = 1; i < animationOptions.length; i++) {
      timeline.add({
        ...animationOptions[i],
        targets: element,
      })
    }

    return timeline.play()
  }

  anime({
    ...animationOptions,
    loop,
    targets: element,
  })
}
