import { CloudflareImage } from '@components/primitives/Image/Cloudflare'
import Link from '@components/primitives/Link'
import { MediaCard } from '@components/primitives/MediaCard'
import { classMerge } from '@components/utilities/classMerge'
import { faMicroscope } from '@fortawesome/pro-regular-svg-icons/faMicroscope'
import { type BackgroundColor } from '@lib/colors'
import { type Url } from '@lib/routes'
import DynamicRoutes from '@lib/routes/DynamicRoutes'
import {
  trackEventWithFacebook,
  TrackingEvent,
  TrackingSource,
} from '@lib/tracking'
import { conditionSearch } from '@modules/images/pages'
import type { ConditionCardData } from '../consts'

/** The section immediately below the home page's hero. **/
export function ConditionSectionCard({
  backgroundColor,
  condition,
  description,
}: Pick<ConditionCardData, 'condition' | 'description'> & {
  backgroundColor: BackgroundColor
}) {
  const url = DynamicRoutes.trialSearch({ condition })
  const trackDirectoryClick = () =>
    trackEventWithFacebook(TrackingEvent.DIRECTORY_CARD_CLICKED, {
      path: url.toString(),
      source: TrackingSource.HOMEPAGE,
    })

  return (
    <div onClick={trackDirectoryClick}>
      <DirectoryLinkCard
        backgroundColor={backgroundColor}
        className='max-w-md flex-none'
        description={description}
        mediaCardClassName='min-h-[345px]'
        subtitle={`${condition} Trials`}
        url={url}
      />
    </div>
  )
}

export const DirectoryLinkCard = ({
  backgroundColor,
  className,
  description,
  mediaCardClassName,
  subtitle,
  url,
}: {
  backgroundColor?: BackgroundColor
  className?: string
  description?: string
  mediaCardClassName?: string
  subtitle: string
  url: Url
}) => {
  const bg = backgroundColor ?? 'bg-white'
  return (
    <div className={className}>
      <Link href={url} rel='nofollow'>
        <MediaCard
          className={classMerge(
            'overflow-hidden rounded-t-lg border border-neutral200 shadow-none',
            mediaCardClassName,
          )}
          desc={description}
          icon={faMicroscope}
          iconClassName='text-neutral500'
          subtitle={subtitle}
        >
          <div
            className={classMerge(
              'max-h-[240px] overflow-hidden rounded-t-[7px]', // 7px allows us to have an 8px radius with 1px border width
              bg,
            )}
          >
            <CloudflareImage
              alt={subtitle ?? 'Search for clinical trials'}
              className='mt-6'
              imageId={conditionSearch}
            />
          </div>
        </MediaCard>
      </Link>
    </div>
  )
}
